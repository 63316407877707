<template>
  <b-card>
    <h2 class="text-primary">{{ $t("Video Upload") }}</h2>
    <!-- media -->
    <b-media no-body>
      <b-media-aside>
        <!-- Video Önizlemesi -->
        <div v-if="optionsLocal.videoUrl" class="mt-1">
          <iframe
            v-if="isFrameLink(optionsLocal.videoUrl)"
            id="video-preview"
            width="100%"
            height="240"
            :src="videoEmbedUrl"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          >
          </iframe>

          <video
            v-else
            id="video-preview"
            controls
            width="100%"
            :src="optionsLocal.videoUrl"
            height="240"
          ></video>
        </div>
        <div v-else class="mt-1"></div>
        <!--/ avatar -->
      </b-media-aside>
      <b-media-body class="mt-75 ml-75">
        <!-- upload button -->

        <b-row
          ><b-col>
            <div
              v-if="optionsLocal.videoUrl == ''"
              style="float: left; width: 100%;"
            >
              <UploadVideos @onFileUploadSuccess="showMedia"></UploadVideos>
            </div>
            <div v-else>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :variant="'danger'"
                size="sm"
                class="mb-75 mr-75"
                :state="Boolean(file)"
                v-model="file"
                @click="deleteFile()"
              >
                {{ $t("Delete") }}
              </b-button>
            </div>
          </b-col></b-row
        >

        <b-form-file
          ref="refInputEl"
          v-model="profileFile"
          accept=".mp4, .mov, .avi"
          :hidden="true"
          plain
          @input="fileSelected"
        />

        <b-card-text>{{
          $t("Allowed MP4, MOV or AVI. Max size of 300 MB")
        }}</b-card-text>
      </b-media-body>
    </b-media>

    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col sm="12" cols="6" class="mt-75">
          <b-badge variant="primary">
            {{ optionsLocal.lessonType }}
          </b-badge>
        </b-col>
        <b-col sm="12" class="mt-75">
          <b-form-group :label="$t('Video URL')" label-for="course-title">
            <b-form-input
              v-model="optionsLocal.videoUrl"
              placeholder="https://googlecloud/example.com"
              name="title"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            @click.prevent="sendInformation"
          >
            {{ $t("Save Changes") }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BBadge,
} from "bootstrap-vue";
import UploadVideos from "@/@core/components/resumable-vue/UploadVideos.vue";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";
import CategorySelector from "../../course-category/components/CategorySelector.vue";
import AuthorSelector from "../../instructor/AuthorSelector.vue";

export default {
  components: {
    UploadVideos,
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    CategorySelector,
    AuthorSelector,
    BBadge,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
    courseId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      localOptions: {},
      videoURL: "",
      isLoading: false,
      file: null,
      isUpdated: false,
      optionsLocal: JSON.parse(JSON.stringify(this.generalData.data)),
      profileFile: null,
    };
  },
  computed: {
    videoEmbedUrl() {
      const url = new URL(this.optionsLocal.videoUrl);
      const videoId = url.searchParams.get("v");
      return `https://www.youtube.com/embed/${videoId}`;
    },
  },
  methods: {
    findBucketName() {
      return this.optionsLocal.videoUrl.split("/").pop();
    },
    showMedia(response) {
      this.optionsLocal.featuredImage = response.url;
      this.isUpdated = true;
      this.isLoading = false;
      this.$bvToast.toast(`${response.message}`, {
        title: "Success",
        autoHideDelay: 5000,
        appendToast: true,
        variant: "success",
        solid: true,
      });
      this.optionsLocal.videoUrl = response.url;
    },
    isFrameLink(link) {
      // console.log("VIDEO URL", link)
      if (link.includes("storage.googleapis")) {
        return false;
      }
      return true;
    },
    resetFile() {
      this.file = null;
      this.profileFile = null;
    },
    clearFiles() {
      this.$refs["file-input"].reset();
    },

    fileSelected(payload) {
      const formData = new FormData();
      formData.append("file", payload);
      formData.append("courseId", this.courseId);
      formData.append("acc", 3);
      formData.append("platform", "webadmin");
      this.isLoading = true;

      // const file = payload.target.files[0];
      if (payload) {
        this.optionsLocal.videoUrl = URL.createObjectURL(payload).split(":")[1];
        //console.log("videoURL => ", this.optionsLocal.videoUrl)
      }
      axios
        .post("/upload/admin", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          //console.log("SUCCESS RESPNSE IN VIDEO UPLOAD", response);
          this.optionsLocal.featuredImage = response.data.url;
          this.isUpdated = true;
          this.isLoading = false;
          this.$bvToast.toast(`${response.data.message}`, {
            title: "Success",
            autoHideDelay: 5000,
            appendToast: true,
            variant: "success",
            solid: true,
          });
          this.optionsLocal.videoUrl = response.data.url;
        })
        .catch((error) => {
          this.isLoading = false;
          this.$bvToast.toast(`${error.response.data.message}`, {
            title: "Error",
            autoHideDelay: 5000,
            appendToast: true,
            variant: "danger",
            solid: true,
          });
        });
    },

    authorChanged(payload) {
      //console.log(payload);
      this.optionsLocal.ClientInstructors = Array.isArray(payload)
        ? payload
        : [payload];

      //console.log(this.optionsLocal);
    },

    courseModeUpdated(payload) {
      this.optionsLocal.coursemode = payload.name;
    },

    courseCategoryUpdated(payload) {
      this.optionsLocal.CourseCategories = Array.isArray(payload)
        ? payload
        : [payload];
    },
    deleteFile() {
      this.isLoading = true;
      if (this.isFrameLink(this.optionsLocal.videoUrl)) {
        this.isLoading = false;
        this.isUpdated = false;
        this.optionsLocal.videoUrl = "";
        this.sendInformation();
      } else {
        axios({
          method: "delete",
          url: `/upload/${this.findBucketName()}`,
        })
          .then((r) => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Success`,
                icon: "CheckCircleIcon",
                variant: "success",
              },
            });
            this.isLoading = false;
            this.isUpdated = false;
            this.optionsLocal.videoUrl = "";
            this.sendInformation();
          })
          .catch((r) => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Error`,
                icon: "AlertOctagonIcon",
                variant: "warning",
              },
            });
            this.isLoading = false;
          });
      }
    },
    sendInformation() {
      this.isLoading = true;
      axios({
        method: "put",
        url: `/lms/admin/course/updatelessondetail/${this.courseId}`,
        data: {
          info: "videourl",
          file: null,
          content: this.optionsLocal,
        },
      })
        .then((r) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Success`,
              icon: "CheckCircleIcon",
              variant: "success",
            },
          });
          this.isLoading = false;
          this.isUpdated = false;
          setTimeout(() => window.location.reload(), 1000);
        })
        .catch((r) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "AlertOctagonIcon",
              variant: "warning",
            },
          });
          this.isLoading = false;
        });
      return;
    },
    resetForm() {
      this.optionsLocal = JSON.parse(JSON.stringify(this.generalData.data));
    },

    uploadFile(paylod) {
      this.optionsLocal = JSON.parse(JSON.stringify(this.generalData.data));
    },
  },
  mounted() {},
  setup() {
    const refInputEl = ref(null);
    const previewEl = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl);

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    };
  },
};
</script>
